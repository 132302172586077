/* Disclaimer.css */
/* body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
} */

.disclaimer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
}

.disclaimer-box {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  margin: 20px;
  text-align: center;
}

.disclaimer-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
}

.disclaimer-content {
  text-align: left;
  margin-bottom: 20px;
}

.disclaimer-content p {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.disclaimer-content a {
  color: #1e90ff;
  text-decoration: none;
  font-weight: bold;
}

.disclaimer-content a:hover {
  text-decoration: underline;
}

.disclaimer-box button {
  background-color: #1e90ff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.disclaimer-box button:hover {
  background-color: #1c86ee;
}
