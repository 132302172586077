.vitals__body{
    display: grid;
    place-content: center center;
    place-items: center center;
}

.scan__vitalBox{
    display: flex;
    margin: 10px;
    place-self: center center;
}

.plusmin__box{
    grid-column: 1;
    grid-row: 3;
    font-size: small;
    place-self: end center;
}
.plusMin{
    color:royalblue;
}
.scan__Box{
    display: grid;
    width: 200px;
    height: 150px;
    place-content: start center;
    place-items: center center;
    margin: 3px;
    /* box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5); */
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}

.scan__label{
    grid-column: 1;
    grid-row: 1;
    font-weight: bold;
    font-size: large;
    color:royalblue;
    font-size:medium;
}

.scan__input{
    grid-column: 1;
    grid-row: 2;
    width: 100px;
    height: 50px;
    outline: 0;
    border: 0;
    font-weight: bolder;
    font-size:x-large;
    text-align: center;
    margin-top: 20px;
}

.divider__container{
    width: 200px;
    margin: 5px;
}